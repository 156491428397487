// Actions
const CHANGE_LOADING_STATE = "changeLoadingState";
const CHANGE_PROJECT_LOADING_STATE = "changeProjectLoadingState";
const CHANGE_ACTIVE_PROJECT = "changeActiveProject";
const LOAD_LANDLORD_DETAILS = "loadLandlordDetails";
const LOAD_PROJECT_AVAILABILITY = "loadProjectAvailability";
const CHANGE_FILTER_STATE = "changeFilterState";
const TOGGLE_INFO_BOX = "toggleInfoBox";
const DOWNLOAD_FILE = "downloadFile";
const RESET_FILTERS = "resetFilters";
const RESET_FLOOR_FILTER = "resetFloorFilter";
const LOAD_TOTAL_AVAILABLE_RESULTS = "loadTotalAvailableResults";

// Mutations
const SET_LANDLORD_DETAILS = "setLandlordDetails";
const SET_LOADING_STATE = "setLoadingState";
const SET_PROJECT_LOADING_STATE = "setProjectLoadingState";
const SET_PROJECT_AVAILABILITY = "setProjectAvailability";
const SET_INFO_BOX_STATE = "setInfoBoxState";
const SET_ACTIVE_PROJECT = "setActiveProject";
const SET_FILTER_STATE = "setFilterState";
const SET_EMPTY_FILTERS = "setEmptyFilters";
const SET_TOTAL_AVAILABLE_RESULTS = "setTotalAvailableResults";

// Namespace
const NAMESPACE = "project"

export const withNamespace = (string: string) => {
    return `${ NAMESPACE }/${ string }`;
}

export default {
    action: {
        CHANGE_LOADING_STATE,
        CHANGE_PROJECT_LOADING_STATE,
        CHANGE_ACTIVE_PROJECT,
        LOAD_LANDLORD_DETAILS,
        LOAD_PROJECT_AVAILABILITY,
        CHANGE_FILTER_STATE,
        TOGGLE_INFO_BOX,
        DOWNLOAD_FILE,
        RESET_FILTERS,
        RESET_FLOOR_FILTER,
        LOAD_TOTAL_AVAILABLE_RESULTS,
    },
    mutation: {
        SET_LANDLORD_DETAILS,
        SET_LOADING_STATE,
        SET_ACTIVE_PROJECT,
        SET_PROJECT_LOADING_STATE,
        SET_PROJECT_AVAILABILITY,
        SET_INFO_BOX_STATE,
        SET_FILTER_STATE,
        SET_EMPTY_FILTERS,
        SET_TOTAL_AVAILABLE_RESULTS,
    },
    namespace: NAMESPACE,
    withNamespace
}