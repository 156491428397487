// Actions
const LOAD_OFFER_DETAILS = "loadOfferDetails";

//mutations
const SET_OFFER_DETAILS = "setOfferDetails"

// Getters
const GET_OFFER = "getOffer";

// Namespace
const NAMESPACE = "offer"

export const withNamespace = (string: string) => {
    console.log("withNamespace", string)
    return `${NAMESPACE}/${string}`;
}

export default {
    action: {
        LOAD_OFFER_DETAILS
    },
    mutation: {
        SET_OFFER_DETAILS
    },
    getter: {
        GET_OFFER
    },
    namespace: NAMESPACE,
    withNamespace
}