<template>
  <div class="input-atom-wrapper">
    <input ref="input" v-if="type !== types.TEXTAREA" :type="type" v-model="inputVal" :placeholder="placeholder" :name="name"/>
    <textarea ref="textarea" v-else v-model="inputVal" :placeholder="placeholder"></textarea>
    <span class="error-message" v-if="error">{{ error }}</span>
  </div>
</template>

<script>
export const EnumInputTypes = {
  TEXT: 'text',
  DATE: 'date',
  TEXTAREA: 'textarea',
  NUMBER: 'number',
  PASSWORD: 'password',
  TEL: 'tel'
};

export default {
  name: "AtomInput",
  props: {
    value: {
      required: false,
      type: String,
      default: "",
    },
    type: {
      required: false,
      type: String,
      default: EnumInputTypes.TEXT,
    },
    placeholder: {
      required: false,
      type: String,
      default: "Input value...",
    },
    name: {
      required: false,
      type: String,
    },
    error: {
      required: false,
      type: String,
    }
  },
  data() {
    return {
      types: EnumInputTypes,
      // err: this.error,
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    resetInput() {
      this.inputVal = '';
    },
    focus(opts = {}) {
      if (this.type === this.types.TEXTAREA) {
        this.$refs.textarea.focus(opts);
        return;
      }
      return this.$refs.input.focus(opts);
    }
  }
}
</script>

<style lang="scss" scoped>
.input-atom-wrapper {
  margin: 0;
  flex: 1;
  width: 100%;

  .error-message {
    padding: 0.625rem 0 0.938rem;
    width: 100%;
    @include font-main(0.875rem, red, $regular, 1.063rem);
    @media only screen and (max-width: 767px) {
      padding: 0.313rem 0;
      @include font-main(0.75rem, red, $regular, 0.875rem);
    }
  }

  input,
  textarea {
    background: #f5f6fb;
    border: none;
    border-radius: 0.313rem;
    padding: 0.625rem 0.938rem;
    width: 100%;
    @include font-main(0.875rem, $black, $regular, 1.063rem);
    @media only screen and (max-width: 767px) {
      padding: 0.313rem 0.625rem;
      @include font-main(0.75rem, $black, $regular, 1.125rem);
    }

    &:focus-visible {
      border: none !important;
      box-shadow: none !important;
      outline-width: 0 !important;
    }

    &::placeholder {
      color: #bbb4d6;
    }
  }

  input {
    height: 2.5rem;
  }

  textarea {
    height: 6.25rem;
    min-height: 6.25rem;
    max-height: 6.25rem;
    max-width: 100%;
    min-width: 100%;
    @media only screen and (max-width: 767px) {
      height: 5rem;
      min-height: 5rem;
      max-height: 5rem;
    }
  }
}
</style>