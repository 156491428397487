import { BaseApiModule, AuthApiModule, LandlordApiModule, OfferingApiModule } from "./modules";

class WebApi {

    public readonly base: BaseApiModule;
    public readonly auth: AuthApiModule;
    public readonly landlord: LandlordApiModule;
    public readonly offering: OfferingApiModule;

    constructor() {
        this.base = new BaseApiModule();
        this.auth = new AuthApiModule();
        this.landlord = new LandlordApiModule();
        this.offering = new OfferingApiModule();
    }


}

const webApiInstance = new WebApi();

export default webApiInstance;