import BaseApiModule from "./BaseApiModule";

class OfferingApiModule extends BaseApiModule {
    readonly controller: string;

    constructor() {
        super();
        this.controller = 'offering';
    }

    contact(): string {
        const methodUrl = "from-client-interface"
        return `${ this.baseUrl }/${ this.controller }/${ methodUrl }`
    }

    requestOffer(): string {
        const methodUrl = "from-client-interface"
        return `${ this.baseUrl }/${ this.controller }/${ methodUrl }`
    }

    requestMeetingRoom(): string {
        const methodUrl = "request-meeting-room"
        return `${ this.baseUrl }/${ this.controller }/${ methodUrl }`
    }
}

export default OfferingApiModule;