<template>
  <div id="request-offer-popup" class="molecule-request-offer">
    <div class="popup-screen">
      <span class="btn-close-popup" @click="closeModal"
        ><AtomIcon icon="xmark" :size="16"
      /></span>
      <div class="row request-offer">
        <div class="col col-12">
          <div class="scroll-disable">
            <form
              @submit="submitForm"
              action="javascript:void(0);"
              id="request-offer"
              ref="request-offer-form"
            >
              <div class="row">
                <div class="col col-xs-12 col-6 col-left-popup">
                  <div class="title-subtitle mt-0">
                    <h3>I am interested in:</h3>
                  </div>
                  <div
                    class="spaces-selected-result"
                    v-if="requestedSpaces.length > 0"
                  >
                    <div
                      class="clearfix space-floor-selected"
                      v-for="(space, i) in requestedSpaces"
                      :key="i"
                    >
                      <div class="row align-items-center">
                        <div class="col col-6" v-if="space.building">
                          <span>{{ space.building.name }}</span>
                        </div>
                        <div class="col col-6 text-right" v-if="space.floor">
                          <span class="when-text"
                            >Floor
                            <span class="nr-cube">{{
                              space.floor.no
                            }}</span></span
                          >
                        </div>
                      </div>
                      <div class="row align-items-center">
                        <div class="col col-9">
                          <div class="info-content">
                            <span class="result-sqm"
                              ><b>{{ space.sqm }}</b>
                              {{ unitOfMeasureString }} GLA</span
                            >
                            <span class="result-max-people"
                              ><b>{{ getSpacePeople(space) }}</b> People</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="title-subtitle">
                    <h3>Company details:</h3>
                  </div>

                  <AtomInput
                    :type="inputTypes.TEXT"
                    :value="companyNumber"
                    @input="companyNumber = $event"
                    placeholder="Company Registration Number"
                  />
                  <AtomInput
                    :type="inputTypes.TEXT"
                    :value="companyName"
                    @input="companyName = $event"
                    placeholder="Company Name*"
                    :error="errors.companyName"
                  />
                  <AtomInput
                    :type="inputTypes.TEXT"
                    :value="companyAddress"
                    @input="companyAddress = $event"
                    placeholder="Company Address"
                  />

                  <div class="title-subtitle">
                    <h3>Personal details:</h3>
                  </div>
                  <AtomInput
                    :type="inputTypes.TEXT"
                    :value="firstName"
                    @input="firstName = $event"
                    placeholder="First Name*"
                    :error="errors.firstName"
                  />
                  <AtomInput
                    :type="inputTypes.TEXT"
                    :value="lastName"
                    @input="lastName = $event"
                    placeholder="Last Name*"
                    :error="errors.lastName"
                  />
                  <AtomInput
                    :type="inputTypes.TEL"
                    :value="phone"
                    @input="phone = $event"
                    placeholder="Phone Number*"
                    :error="errors.phone"
                  />
                  <AtomInput
                    :type="inputTypes.TEXT"
                    :value="email"
                    @input="email = $event"
                    placeholder="Email*"
                    :error="errors.email"
                  />
                </div>
                <div class="col col-xs-12 col-6 col-right-popup">
                  <div class="title-subtitle mt-0" v-if="showVisitSection">
                    <h3>I want a personalised quote</h3>
                  </div>
                  <div class="title-subtitle" v-else>
                    <h3>Reserve the space</h3>
                  </div>
                  <AtomInput
                    :type="inputTypes.TEXTAREA"
                    :value="notes"
                    @input="notes = $event"
                    :placeholder="
                      showVisitSection
                        ? 'Other details:'
                        : 'Please write the date and time interval'
                    "
                  />

                  <div class="title-subtitle" v-if="showVisitSection">
                    <h3>Let’s schedule a visit</h3>
                  </div>
                  <div class="input-box" v-if="showVisitSection">
                    <AtomRadio
                      id="select-date"
                      name="visit"
                      label="Select a date"
                      box="date"
                    />
                    <div class="hide-select" data-tab="select-date">
                      <AtomInput
                        :type="inputTypes.DATE"
                        :value="date"
                        @input="date = $event"
                      />
                    </div>
                    <AtomRadio
                      id="select-interval"
                      name="visit"
                      label="Select an interval"
                      box="interval"
                    />
                    <div class="hide-select" data-tab="select-interval">
                      <AtomRadio
                        id="this-week"
                        name="interval"
                        label="This Week"
                        value="This week"
                      />
                      <AtomRadio
                        id="next-week"
                        name="interval"
                        label="Next Week"
                        value="Next week"
                      />
                      <AtomRadio
                        id="this-month"
                        name="interval"
                        label="This Month"
                        value="This month"
                      />
                      <AtomRadio
                        id="next-month"
                        name="interval"
                        label="Next Month"
                        value="Next month"
                      />
                    </div>
                    <AtomRadio
                      id="i-dont-want-to-visit-the-space"
                      name="visit"
                      label="I don’t want to visit the space"
                      box="no-visit"
                      value="no-visit"
                    />
                    <!--                    <span class="error-message" v-if="errors.generalRadioGroup">{{ errors.generalRadioGroup }}</span>-->
                  </div>
                  <div class="title-subtitle" v-if="showVisitSection">
                    <h3>&nbsp;</h3>
                  </div>
                  <AtomCheck
                    id="checkbox-contact"
                    ref="check"
                    class="row-checkbox"
                    label="I agree to the Terms & Conditions and Privacy Policy*"
                    :error="errors.agree"
                  />

                  <div class="buttons-bottom" style="margin-top: 15px">
                    <p class="required">All fields with * are required</p>
                    <a
                      href="javascript:void(0)"
                      class="btn-red btn-cancel"
                      @click="closeModal"
                      >Cancel</a
                    >
                    <button
                      type="submit"
                      class="btn-green"
                      value="Request Offer"
                    >
                      Request Offer
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomInput, { EnumInputTypes } from "../../atoms/common/AtomInput";
import AtomCheck from "../../atoms/common/AtomCheck";
import AtomIcon from "../../atoms/common/AtomIcon";
import AtomRadio from "../../atoms/common/AtomRadio";
import config from "@/config";

export default {
  name: "MoleculeRequestOffer",
  components: { AtomRadio, AtomIcon, AtomCheck, AtomInput },
  data() {
    return {
      errors: {},
      companyNumber: "",
      companyName: "",
      companyAddress: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      notes: "",
      date: "",
      inputTypes: EnumInputTypes,
    };
  },
  computed: {
    requestedSpaces() {
      const requestOfferSpaces = this.$store.state?.requestOffer?.spaces || []; // get spaces from request offer store
      const spaceCodes = requestOfferSpaces?.map(
        (rs) => `${rs.building}/${rs.floor}/${rs.spaceId}`
      ); // convert spaces params into a string identifier

      const identifiers =
        this.$store.state?.base?.landlordDetails?.spaces?.filter(
          (sp) =>
            spaceCodes.some((sc) => sc === sp.code) &&
            sp.available &&
            sp.is_public
        ) || []; // filter all spaces based on the spaceCode array

      if (!identifiers.length) return;
      const firstAvailableSpace = identifiers[0];

      return [
        {
          id: firstAvailableSpace.id,
          alias: firstAvailableSpace.alias,
          space_code: firstAvailableSpace.space_code,
          people: firstAvailableSpace.people,
          code: firstAvailableSpace.code,
          sqm: firstAvailableSpace.sqm,
          space_type: firstAvailableSpace.space_type,
          building:
            this.$store.state.base?.landlordDetails?.buildings?.find(
              (b) => b.id === firstAvailableSpace.building_id
            ) || null,
          floor:
            this.$store.state.base?.landlordDetails?.floors?.find(
              (fl) => fl.id === firstAvailableSpace.floor_id
            ) || null,
        },
      ];
      // return identifiers.map((i) => {
      //   // return a list of all matched spaces hydrated with building and floor additional info.
      //   return {
      //     ...i,
      //     building:
      //       this.$store.state.base?.landlordDetails?.buildings?.find(
      //         (b) => b.id === i.building_id
      //       ) || null,
      //     floor:
      //       this.$store.state.base?.landlordDetails?.floors?.find(
      //         (fl) => fl.id === i.floor_id
      //       ) || null,
      //   };
      // });
    },
    client() {
      return config.CLIENT;
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.general.unitOfMeasure;
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.general.unitOfMeasure === 1
        ? "sqft"
        : "sqm";
    },
    activeProjectSlug() {
      return this.$store.state?.project?.activeProject || null;
    },
    activeProject() {
      if (!this.activeProjectSlug) return;
      return (
        this.$store.state?.project?.projects?.find(
          (p) => p.slug === this.activeProjectSlug
        ) || null
      );
    },
    showVisitSection() {
      let flag = true;
      for (const sp of this.requestedSpaces) {
        if (!sp.space_type) return flag;
        if (sp.space_type.name.toLowerCase().includes("meeting")) {
          flag = false;
        }
      }
      return flag;
    },
  },
  methods: {
    calcPeople(sqm) {
      if (this.unitOfMeasure === 1) {
        const unitSQM = sqm / 10.763910417;
        return Math.floor(unitSQM / 8);
      }
      return Math.floor(sqm / 8);
    },
    getSpacePeople(space) {
      if (!space) return 0;
      if (typeof space.people !== "undefined") return space.people;
      this.calcPeople(space.sqm);
    },
    async submitForm(e) {
      e.preventDefault();
      if (this.validateInputs() !== null) return;
      if (window && "dataLayer" in window) {
        window.dataLayer.push({
          event: "request_offer_submit",
          category: "request_offer",
          label: "request_offer",
        });
      }

      let projectId = this.activeProject?.id || -1;
      const selectedSpaces = this.requestedSpaces.map((sp) => {
        const space =
          this.$store.state.base?.landlordDetails?.spaces?.find(
            (s) => s.id === sp.id
          ) || null;
        projectId = space?.project_id || -1;
        return {
          spaces: [sp.id],
          handover_date: null,
          max_workplaces: space?.workplaces || 0,
        };
      });
      const payload = {
        offerData: {
          projectId,
          landlordId: this.activeProject?.landlord_id || -1,
          name: this.companyName,
          companyName: this.companyName,
          companyRegistrationNumber:
            this.companyNumber === "" ? "-" : this.companyNumber,
          companyAddress:
            this.companyAddress === "" ? "-" : this.companyAddress,
          receivedOn: new Date(),
          message: this.notes === "" ? null : this.notes,
        },
        selectedSpaces,
        contactData: {
          firstname: this.firstName,
          lastname: this.lastName,
          phone: this.phone,
          email: this.email,
          visible: true,
        },
      };

      if (!this.showVisitSection) {
        // we are handling the meeting rooms
        const {
          data: { error: meetingRoomsEmailError, result },
        } = await this.$request.POST(
          this.$urls.offering.requestMeetingRoom(),
          payload
        );
        if (meetingRoomsEmailError) {
          alert("Something wrong happened! Please try again later!");
          return;
        }
        this.$refs["request-offer-form"].reset();
        this.$parent.$refs.successMessage.toggleShow();
        return;
      }
      payload.offerData.noVisit = false;
      payload.offerData.interval = "No interval";
      payload.offerData.proposedVisitDate = null;
      const selector = "input[type=radio]:checked";
      const checkedRadio = this.$el?.querySelector(selector) || null;
      if (checkedRadio) {
        const checkedRadioType = checkedRadio?.parentNode?.getAttribute(
          "data-box"
        );
        switch (checkedRadioType) {
          case "no-visit":
            payload.offerData.noVisit = true;
            break;
          case "interval": {
            payload.offerData.interval =
              checkedRadio?.parentNode?.parentNode
                ?.querySelector(".hide-select[data-tab='select-interval']")
                ?.querySelector(selector)?.value || "No interval";
            break;
          }
          case "date": {
            if (this.date === "") {
              payload.offerData.proposedVisitDate = null;
            } else {
              payload.offerData.proposedVisitDate = this.date;
            }
            break;
          }
          default:
            break;
        }
      }

      const {
        data: { error: serverError },
      } = await this.$request.POST(this.$urls.offering.requestOffer(), payload);
      if (serverError) {
        alert("Something wrong happened! Please try again later!");
        return;
      }
      this.$refs["request-offer-form"].reset();
      this.$parent.$refs.successMessage.toggleShow();
    },
    validateInputs() {
      this.errors = {};
      if (this.companyName === "") {
        this.errors.companyName = "Company name is required.";
      }
      if (this.firstName === "") {
        this.errors.firstName = "First Name is required.";
      }
      if (this.lastName === "") {
        this.errors.lastName = "Last Name is required.";
      }
      if (this.phone === "" || !this.phone.match(/^[+]?\d{10,13}$/)) {
        this.errors.phone = "Phone Number is not valid.";
      }
      if (!this.validEmail(this.email)) {
        this.errors.email = "Email is not valid.";
      }
      if (!this.$refs.check.checked) {
        this.errors.agree = "Please agree to the Terms and Conditions first.";
      }

      // const checkedRadio = this.$el.querySelector('input[type=radio]:checked');
      // if (checkedRadio === null) {
      //   this.errors.generalRadioGroup = 'Please select one option.';
      // } else {
      //   const checkedRadioType = checkedRadio.parentNode.getAttribute('data-box');
      //   switch (checkedRadioType) {
      //     case 'interval': {
      //       const parentHiddenNode = checkedRadio.parentNode.parentNode.querySelector(".hide-select[data-tab='select-interval']");
      //       this.errors.generalRadioGroup = 'Please select an interval.';
      //       if (parentHiddenNode.querySelector('input[type=radio]:checked') !== null) {
      //         delete this.errors.generalRadioGroup;
      //       }
      //       break;
      //     }
      //     case 'date': {
      //       if (this.date === '') {
      //         this.errors.generalRadioGroup = 'Please select a date.';
      //       }
      //       break;
      //     }
      //   }
      // }

      if (Object.keys(this.errors).length === 0) return null;
      return this.errors;
    },
    validEmail: function (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    closeModal() {
      const modal = this.$store.getters.constants.modal;
      this.$store.dispatch(modal.withNamespace(modal.action.CLOSE_MODAL));
      document.body.classList.remove("disable-scroll");
    },
  },
};
</script>

<style lang="scss">
.molecule-request-offer {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);

  .error-message {
    color: red;
    padding: 0.625rem 0 0.938rem;
    font-size: 0.875rem;
    line-height: 1.063rem;
    font-weight: 400;
    width: 100%;
    @media only screen and (max-width: 767px) {
      font-size: 12px;
      line-height: 14px;
      padding: 5px 0;
    }
  }

  &#request-offer-popup {
    display: block;
  }

  .input-atom-wrapper {
    margin-bottom: 15px;
    flex: none;

    input,
    textarea {
      margin-bottom: 5px;
    }
  }

  .popup-screen {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 90%;
    z-index: 999999;
    background: #fff;
    box-shadow: 0 0 10px $blackOpacity012;
    overflow: hidden;
    padding: 35px 15px;
    transform: translate(-50%, -50%);
    @media only screen and (max-width: 767px) {
      width: 90%;
      height: 95%;
    }

    &.small-popup-screen {
      width: 30%;
      height: auto;
      min-height: 20%;
      @media only screen and (max-width: 1200px) {
        min-height: 10%;
      }
      @media only screen and (max-width: 767px) {
        min-height: 0;
        width: 80%;
        padding: 20px 10px 15px;
      }

      .content-center {
        text-align: center;
        margin: 14px 20px;

        h3 {
          font-size: 16px;
          line-height: 20px;
        }

        .title-subtitle {
          margin-bottom: 20px;
        }
      }
    }

    .btn-close-popup {
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 0;
      width: 30px;
      height: 30px;
      line-height: 30px;
      display: block;
      z-index: 99;
      text-align: center;
      cursor: pointer;

      .icon {
        font-size: 22px !important;
      }
    }

    .request-offer {
      height: 100%;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      overflow-y: auto;

      .scroll-disable {
        width: 966px;
        max-width: 100%;
        margin: 0 auto;

        .col-left-popup {
          padding-right: 35px;
        }

        .col-right-popup {
          padding-left: 35px;
          padding-bottom: 55px;

          .buttons-bottom {
            margin-top: 55px;

            a {
              margin-right: 20px;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        .required {
          margin-bottom: 20px;
          @include font-main(12px, #736f6f, $bold, 16px);
        }

        .spaces-selected-result {
          padding: 0 5px 0 0;
          max-height: 65px;
          min-height: 65px;
          overflow: hidden;
          overflow-y: auto;
          margin-bottom: 20px;
          position: relative;
          width: 100%;
          display: inline-block;
        }

        .space-floor-selected {
          cursor: initial;
          margin-bottom: 15px;

          h6 {
            display: block;
            @include font-main(16px, $black, $semiBold, 19px);
            margin-bottom: 15px;
            text-transform: uppercase;
          }

          span {
            display: inline-block;
            @include font-main(12px, rgba(0, 0, 0, 0.6), $semiBold, 16px);
            letter-spacing: 0.6px;
            text-transform: uppercase;
            margin-right: 25px;

            b {
              font-size: 12px;
              line-height: 16px;
            }

            &:last-child {
              margin-right: 0;
            }

            span {
              margin: -1px 0 0;
              color: $black;
              display: inline-block;
              vertical-align: middle;
            }
          }

          &:hover {
            &:before {
              opacity: 0;
            }
          }

          .hoverButtons {
            display: none;
          }
        }

        .row-floor {
          cursor: initial;
          margin-bottom: 20px;

          h6 {
            display: block;
            @include font-main(16px, $black, $semiBold, 19px);
            margin-bottom: 15px;
            text-transform: uppercase;
          }

          span {
            display: inline-block;
            @include font-main(12px, rgba(0, 0, 0, 0.6), $semiBold, 16px);
            letter-spacing: 0.6px;
            text-transform: uppercase;
            margin-right: 25px;

            &:last-child {
              margin-right: 0;
            }

            span {
              margin: 0;
              color: $black;
            }
          }

          &:hover {
            &:before {
              opacity: 0;
            }
          }

          .hoverButtons {
            display: none;
          }
        }

        h6 {
          display: inline-block;
          vertical-align: bottom;
          @include font-main(12px, $black, $bold, 16px);
        }

        .title-subtitle {
          margin-top: 30px;
          margin-bottom: 20px;

          &.mt-0 {
            margin-top: 0;
          }

          h3 {
            margin-bottom: 5px;
            text-transform: uppercase;
            @include font-main(16px, $black, $semiBold, 19px);
          }

          p {
            @include font-main(13px, $black, $bold, 20px);
          }
        }

        h4 {
          margin-bottom: 15px;
          @include font-main(13px, $black, $bold, 20px);
        }

        .input-style {
          width: 100%;
          margin-bottom: 20px;

          textarea {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            height: 120px;
            min-height: 120px;
            max-height: 120px;
            padding: 5px 10px;
          }
        }

        .atom-radio-box {
          display: block;
          margin-bottom: 20px;
        }

        .hide-select {
          display: none;
          padding: 5px 22px 10px;

          &.active {
            display: block;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .popup-screen {
      .request-offer {
        .scroll-disable {
          width: 700px;

          .spaces-selected-result {
            margin-bottom: 10px;
          }

          .row-floor {
            width: 100%;
            padding: 0;
            margin-bottom: 15px;

            h6 {
              font-size: 10px;
              line-height: 14px;
              margin-bottom: 5px;
            }

            span {
              font-size: 8px;
              line-height: 12px;
              letter-spacing: 0.1px;
              margin-right: 5px;
            }
          }

          .title {
            h2 {
              font-size: 16px;
              line-height: 22px;
            }
          }

          .title-subtitle {
            margin-top: 10px;
            margin-bottom: 10px;

            h3 {
              font-size: 14px;
              line-height: 18px;
            }
          }

          .required {
            @include font-main(8px, #736f6f, $bold, 12px);
          }

          .input-style {
            width: 100%;
            margin-bottom: 8px;

            textarea {
              height: 60px;
              min-height: 60px;
              max-height: 60px;
              padding: 0 5px;
            }
          }

          .hide-select {
            padding: 2px 18px 5px;
          }

          .atom-radio-box {
            margin-bottom: 5px;
          }

          .col-right-popup {
            .buttons-bottom {
              margin-top: 66px;

              .btn {
                margin-right: 5px;
                padding: 5px 10px;
                font-size: 8px !important;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .popup-screen {
      .btn-close-popup {
        right: 10px;
        top: 5px;
        width: 20px;
        height: 20px;

        .icon {
          font-size: 16px;
          line-height: 19px;
        }
      }

      .request-offer {
        .scroll-disable {
          .col-left-popup {
            padding-right: 5px;
          }

          .col-right-popup {
            padding-left: 0;
            padding-right: 5px;
            margin-top: 20px;
            padding-bottom: 0;

            .buttons-bottom {
              margin-top: 30px;

              .btn-green {
                float: right;
              }
            }
          }

          .required {
            @include font-main(10px, #736f6f, $bold, 14px);
          }
        }
      }
    }
  }
}
</style>
