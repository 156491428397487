import {SpaceState} from "./types";
import constants from "./constants";

const {
    SET_SPACE_DETAILS,
    SET_SPACE_VIEW_MODE,
    SET_CURRENT_SPACE,
} = constants.mutation;
export default {
    [SET_SPACE_DETAILS](state: SpaceState, data: any) {
        state.space = data;
        if (data.gallery) {
            state.gallery = data.gallery;
            state.photos = data.gallery.photos;
            state.videos = data.gallery.videos;
        }
    },
    [SET_SPACE_VIEW_MODE](state: SpaceState, expanded: boolean ) {
        state.expanded = expanded;
    },
    [SET_CURRENT_SPACE](state: SpaceState, payload: number|null) {
        state.currentSpaceId = payload;
    },
}