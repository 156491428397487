<template>
  <div id="bright-spaces-app">
    <router-view />
    <OrganismModals />
    <OrganismPageLoader />
  </div>
</template>

<script type="text/javascript">
import Vue from "vue";
import OrganismPageLoader from "@/components/organisms/common/OrganismPageLoader";
import OrganismModals from "@/components/organisms/OrganismModals";

export default Vue.extend({
  name: "Root",
  components: {
    OrganismPageLoader,
    OrganismModals,
  },
  beforeMount() {
    document.addEventListener(
      "readystatechange",
      this.documentReadyEventHandler
    );
  },
  beforeDestroy() {
    document.removeEventListener(
      "readystatechange",
      this.documentReadyEventHandler
    );
  },
  computed: {
    base() {
      return this.$store.getters.constants.base;
    },
  },
  methods: {
    documentReadyEventHandler() {
      this.$store.dispatch(
        this.base.withNamespace(this.base.action.CHANGE_DOCUMENT_READY_STATE),
        true
      );
    },
  },
});
</script>

<style lang="scss">
@import "scss/main";

#bright-spaces-app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
}
</style>
