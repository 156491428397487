import Vue from "vue";
import Vuex, { Store } from "vuex";
import modules, {
  baseConstants,
  modalConstants,
  offerConstants,
  projectConstants,
  requestOfferConstants,
  spaceConstants,
} from "./modules";
import { WebApi, WebRequest } from "../helpers/api";
import VueRouter from "vue-router";

Vue.use(Vuex);

const defaultMainState: any = {};

export const createStore = function (router: VueRouter) {
  return new Vuex.Store({
    state: defaultMainState,
    mutations: {},
    actions: {},
    modules: modules,
    getters: {
      constants() {
        return {
          [baseConstants.namespace]: baseConstants,
          [projectConstants.namespace]: projectConstants,
          [modalConstants.namespace]: modalConstants,
          [requestOfferConstants.namespace]: requestOfferConstants,
          [offerConstants.namespace]: offerConstants,
          [spaceConstants.namespace]: spaceConstants,
        };
      },
      requestHelper() {
        return WebRequest;
      },
      urlsHelper() {
        return WebApi;
      },
      router() {
        return router;
      },
      cdnBase(state) {
        return state.base.meta.general.cdnBase;
      },
      logoImage(state) {
        return state.base.meta.general.logo;
      },
      logoImageWhite(state) {
        return state.base.meta.general.logoWhite;
      },
      getActiveProjectSettings(state) {
        const allSettings = state.base.meta.general.modelDisplaySettings;
        return allSettings[state.project.activeProject];
      },
      getActiveProjectData(state) {
        return state.project.projects.find(
          (item: any) => item.slug === state.project.activeProject
        );
      },
      getShortlist(state) {
        return state.base.meta.general.shortlist;
      },
      getSkipBuildingInit(state) {
        return state.base.skipBuildingInit || false;
      },
      getFirstAvailableSpaceIdWhenBuildingModelIsNot(state): number | false {
        if (!state.base.skipBuildingInit) return false;

        const landlordDetails = state.base.landlordDetails;
        if (!landlordDetails?.projects?.length) return false;

        let firstProject = landlordDetails.projects[0];
        if (typeof state.project.activeProject !== undefined) {
          const found = landlordDetails.projects.find(
            (project: any) => project.slug === state.project.activeProject
          );
          if (found) {
            firstProject = found;
          }
        }
        const firstBuilding = landlordDetails?.buildings?.find(
          (building: any) => building.project_id === firstProject.id
        );
        if (!firstBuilding) return false;

        const floors =
          landlordDetails?.floors?.filter(
            (floor: any) => floor.building_id === firstBuilding.id
          ) || [];
        const lowestOrderArr = floors.map((floor: any) => floor.order_no);
        const lowestFloor = floors.find(
          (floor: any) => floor.order_no === Math.min(...lowestOrderArr)
        );
        if (!lowestFloor) return false;

        const firstAvailableSpace = landlordDetails?.spaces?.find(
          (space: any) =>
            space.floor_id === lowestFloor.id &&
            space.available &&
            space.is_public &&
            [
              "Team office",
              "Management office",
              "Executive office",
              "Default office",
            ].includes(space.space_type.name)
        );
        if (!firstProject || !firstAvailableSpace) return false;
        return firstAvailableSpace.id;
      },
    },
  });
};
