import { ActionContext } from "vuex";
import constants from "./constants";

const {
  ADD_SPACE,
  REMOVE_SPACE,
  CLEAR_STATE,
  CHANGE_CART_SPACES_STATE,
} = constants.action;
const {
  SET_SPACES,
  CLEAR_STATE: MUTATION_CLEAR_STATE,
  SET_CART_SPACES_STATE,
} = constants.mutation;

export default {
  [ADD_SPACE](
    { commit, state, rootState }: ActionContext<any, any>,
    payload: any
  ) {
    const currentSpaces = [...state.spaces];
    const duplicateSpace = currentSpaces.find((s) => s.id === payload.id);
    if (duplicateSpace) return;
    currentSpaces.push(payload);
    commit(SET_SPACES, currentSpaces);
  },
  [REMOVE_SPACE](
    { commit, state, rootState }: ActionContext<any, any>,
    payload: any
  ) {
    const currentSpaces = [...state.spaces];
    let foundIndex = -1;
    currentSpaces.find((s, i) => {
      if (s.id !== payload.id) {
        return false;
      }
      foundIndex = i;
      return true;
    });
    if (foundIndex !== -1) {
      currentSpaces.splice(foundIndex, 1);
      commit(SET_SPACES, currentSpaces);
    }
  },
  [CLEAR_STATE]({ commit }: ActionContext<any, any>) {
    commit(MUTATION_CLEAR_STATE);
  },
  [CHANGE_CART_SPACES_STATE](
    { commit, rootState }: ActionContext<any, any>,
    payload: boolean
  ) {
    commit(SET_CART_SPACES_STATE, payload);
  },
};
