import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import {BaseState} from "./types";

const state: BaseState = {
    sidebarOpen: false,
    infoOpen: false,
    mobileAvailabilityMenuOpen: false,
    meta: {},
    pageLoading: true,
    documentIsReady: false,
    landlordDetails: {},
    skipBuildingInit: false,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}