import {BaseState} from "./types";
import constants from "./constants";

const {
    SET_METADATA,
    SET_SIDEBAR_STATE,
    SET_PAGE_LOADING_STATE,
    SET_INFO_STATE,
    SET_MOBILE_AVAILABILITY_MENU,
    SET_DOCUMENT_READY_STATE,
    SET_SKIP_BUILDING_INIT
} = constants.mutation;

export default {
    [SET_SIDEBAR_STATE](state: BaseState, isOpen: any) {
        state.sidebarOpen = isOpen;
    },
    [SET_METADATA](state: BaseState, metaData: any) {
        state.meta = metaData;
    },
    [SET_INFO_STATE](state: BaseState, isOpen: any) {
        state.infoOpen = isOpen;
    },
    [SET_PAGE_LOADING_STATE](state: BaseState, payload: boolean) {
        state.pageLoading = payload;
    },
    [SET_MOBILE_AVAILABILITY_MENU](state: BaseState, payload: boolean) {
        state.mobileAvailabilityMenuOpen = payload;
    },
    [SET_DOCUMENT_READY_STATE](state: BaseState, payload: boolean) {
        state.documentIsReady = payload;
    },
    [SET_SKIP_BUILDING_INIT](state: BaseState, {skipBuildingInit = false}: { skipBuildingInit: boolean }) {
        state.skipBuildingInit = skipBuildingInit;
    },
}