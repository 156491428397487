import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import {ProjectState} from "./types";

const state: ProjectState = {
    isLoading: true,
    activeProject: "",
    projects: [],
    buildings: [],
    project: {
        isLoading: true,
        buildings: [],
        floors: [],
        spaces: []
    },
    infoBox: {
        open: false
    },
    filters: {
        building: -1,
        floor: -1,
        workspaces: -1,
        spaceType: -1,
        amenities: [],
    },
    availabilityResults: {
        availableSpaces: 0
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}