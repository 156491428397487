import { ActionContext } from "vuex";
import constants from "./constants";

const {
  TOGGLE_SIDEBAR,
  CHANGE_SIDEBAR_STATE,
  LOAD_METADATA,
  CHANGE_PAGE_LOADING_STATE,
  TOGGLE_MOBILE_AVAILABILITY_MENU,
  CHANGE_MOBILE_AVAILABILITY_MENU,
  CHANGE_DOCUMENT_READY_STATE,
  CHANGE_SKIP_BUILDING_INIT,
} = constants.action;
const {
  SET_METADATA,
  SET_SIDEBAR_STATE,
  SET_PAGE_LOADING_STATE,
  SET_MOBILE_AVAILABILITY_MENU,
  SET_DOCUMENT_READY_STATE,
  SET_SKIP_BUILDING_INIT,
} = constants.mutation;

export default {
  [TOGGLE_SIDEBAR]({ commit, state }: ActionContext<any, any>) {
    commit(SET_SIDEBAR_STATE, !state.sidebarOpen);
  },
  [TOGGLE_MOBILE_AVAILABILITY_MENU]({
    commit,
    state,
  }: ActionContext<any, any>) {
    commit(SET_MOBILE_AVAILABILITY_MENU, !state.mobileAvailabilityMenuOpen);
  },
  [CHANGE_MOBILE_AVAILABILITY_MENU](
    { commit, state }: ActionContext<any, any>,
    payload: boolean
  ) {
    commit(SET_MOBILE_AVAILABILITY_MENU, payload);
  },
  [CHANGE_SIDEBAR_STATE](
    { commit, state }: ActionContext<any, any>,
    payload: boolean
  ) {
    commit(SET_SIDEBAR_STATE, payload);
  },
  [LOAD_METADATA]({ commit, state }: ActionContext<any, any>, payload: any) {
    if (payload.general.customJavascriptEnabled) {
      payload.general.customJavascript = "";
    }
    commit(SET_METADATA, payload);
  },
  [CHANGE_PAGE_LOADING_STATE](
    { commit, state }: ActionContext<any, any>,
    payload: boolean
  ) {
    commit(SET_PAGE_LOADING_STATE, payload);
  },
  [CHANGE_DOCUMENT_READY_STATE](
    { commit, state }: ActionContext<any, any>,
    payload: boolean
  ) {
    commit(SET_DOCUMENT_READY_STATE, payload);
  },
  [CHANGE_SKIP_BUILDING_INIT](
    { commit }: ActionContext<any, any>,
    { skipBuildingInit = false }: { skipBuildingInit: boolean }
  ) {
    commit(SET_SKIP_BUILDING_INIT, { skipBuildingInit });
  },
};
