var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c("div", { staticClass: "organism-page-loader" }, [
        _c(
          "div",
          { staticClass: "border-element" },
          [
            _c("AtomCircleProgressLoader", {
              attrs: {
                size: 296,
                width: 2,
                color: "#000000",
                progress: _vm.percentage
              }
            }),
            _c("AtomLogo")
          ],
          1
        ),
        _c("div", { staticClass: "bright-logo" }, [
          _c(
            "a",
            { attrs: { href: "https://brightspaces.tech/", target: "_blank" } },
            [
              _c("img", {
                attrs: {
                  src: _vm.resourceUrl("images/default-logo.svg"),
                  alt: "Bright"
                }
              })
            ]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }