import VueRouter from "vue-router";

export const initMiddleware = function (router: VueRouter) {
  /* AuthGuard Middleware */
  router.beforeEach((to, from, next) => {
    if (router.app.$store) {
      const base = router.app.$store.getters.constants.base;
      router.app.$store.dispatch(
        base.withNamespace(base.action.CHANGE_SIDEBAR_STATE),
        false
      );
      if (!to.hash?.includes("#")) {
        router.app.$store.dispatch(
          base.withNamespace(base.action.CHANGE_PAGE_LOADING_STATE),
          true
        );
      }
    }
    next();
  });
};
