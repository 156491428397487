<template>
  <div v-if="!showModal"></div>
  <OrganismRequestOfferModal v-else-if="showModal && modalType === 'request-offer'" />
</template>

<script>
import OrganismRequestOfferModal from "./project/OrganismRequestOfferModal";
export default {
  name: "OrganismModals",
  components: {OrganismRequestOfferModal},
  computed: {
    showModal() {
      return this.$store.state.modal.showModal;
    },
    modalType() {
      return this.$store.state.modal.modalType;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>