var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "molecule-request-offer",
      attrs: { id: "request-offer-popup" }
    },
    [
      _c("div", { staticClass: "popup-screen" }, [
        _c(
          "span",
          { staticClass: "btn-close-popup", on: { click: _vm.closeModal } },
          [_c("AtomIcon", { attrs: { icon: "xmark", size: 16 } })],
          1
        ),
        _c("div", { staticClass: "row request-offer" }, [
          _c("div", { staticClass: "col col-12" }, [
            _c("div", { staticClass: "scroll-disable" }, [
              _c(
                "form",
                {
                  ref: "request-offer-form",
                  attrs: { action: "javascript:void(0);", id: "request-offer" },
                  on: { submit: _vm.submitForm }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col col-xs-12 col-6 col-left-popup" },
                      [
                        _vm._m(0),
                        _vm.requestedSpaces.length > 0
                          ? _c(
                              "div",
                              { staticClass: "spaces-selected-result" },
                              _vm._l(_vm.requestedSpaces, function(space, i) {
                                return _c(
                                  "div",
                                  {
                                    key: i,
                                    staticClass: "clearfix space-floor-selected"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "row align-items-center" },
                                      [
                                        space.building
                                          ? _c(
                                              "div",
                                              { staticClass: "col col-6" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(space.building.name)
                                                  )
                                                ])
                                              ]
                                            )
                                          : _vm._e(),
                                        space.floor
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col col-6 text-right"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "when-text" },
                                                  [
                                                    _vm._v(
                                                      "Floor\n                          "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "nr-cube"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(space.floor.no)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "row align-items-center" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "col col-9" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "info-content" },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "result-sqm" },
                                                  [
                                                    _c("b", [
                                                      _vm._v(_vm._s(space.sqm))
                                                    ]),
                                                    _vm._v(
                                                      "\n                            " +
                                                        _vm._s(
                                                          _vm.unitOfMeasureString
                                                        ) +
                                                        " GLA"
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "result-max-people"
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getSpacePeople(
                                                            space
                                                          )
                                                        )
                                                      )
                                                    ]),
                                                    _vm._v(" People")
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm._m(1),
                        _c("AtomInput", {
                          attrs: {
                            type: _vm.inputTypes.TEXT,
                            value: _vm.companyNumber,
                            placeholder: "Company Registration Number"
                          },
                          on: {
                            input: function($event) {
                              _vm.companyNumber = $event
                            }
                          }
                        }),
                        _c("AtomInput", {
                          attrs: {
                            type: _vm.inputTypes.TEXT,
                            value: _vm.companyName,
                            placeholder: "Company Name*",
                            error: _vm.errors.companyName
                          },
                          on: {
                            input: function($event) {
                              _vm.companyName = $event
                            }
                          }
                        }),
                        _c("AtomInput", {
                          attrs: {
                            type: _vm.inputTypes.TEXT,
                            value: _vm.companyAddress,
                            placeholder: "Company Address"
                          },
                          on: {
                            input: function($event) {
                              _vm.companyAddress = $event
                            }
                          }
                        }),
                        _vm._m(2),
                        _c("AtomInput", {
                          attrs: {
                            type: _vm.inputTypes.TEXT,
                            value: _vm.firstName,
                            placeholder: "First Name*",
                            error: _vm.errors.firstName
                          },
                          on: {
                            input: function($event) {
                              _vm.firstName = $event
                            }
                          }
                        }),
                        _c("AtomInput", {
                          attrs: {
                            type: _vm.inputTypes.TEXT,
                            value: _vm.lastName,
                            placeholder: "Last Name*",
                            error: _vm.errors.lastName
                          },
                          on: {
                            input: function($event) {
                              _vm.lastName = $event
                            }
                          }
                        }),
                        _c("AtomInput", {
                          attrs: {
                            type: _vm.inputTypes.TEL,
                            value: _vm.phone,
                            placeholder: "Phone Number*",
                            error: _vm.errors.phone
                          },
                          on: {
                            input: function($event) {
                              _vm.phone = $event
                            }
                          }
                        }),
                        _c("AtomInput", {
                          attrs: {
                            type: _vm.inputTypes.TEXT,
                            value: _vm.email,
                            placeholder: "Email*",
                            error: _vm.errors.email
                          },
                          on: {
                            input: function($event) {
                              _vm.email = $event
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col col-xs-12 col-6 col-right-popup" },
                      [
                        _vm.showVisitSection
                          ? _c("div", { staticClass: "title-subtitle mt-0" }, [
                              _c("h3", [_vm._v("I want a personalised quote")])
                            ])
                          : _c("div", { staticClass: "title-subtitle" }, [
                              _c("h3", [_vm._v("Reserve the space")])
                            ]),
                        _c("AtomInput", {
                          attrs: {
                            type: _vm.inputTypes.TEXTAREA,
                            value: _vm.notes,
                            placeholder: _vm.showVisitSection
                              ? "Other details:"
                              : "Please write the date and time interval"
                          },
                          on: {
                            input: function($event) {
                              _vm.notes = $event
                            }
                          }
                        }),
                        _vm.showVisitSection
                          ? _c("div", { staticClass: "title-subtitle" }, [
                              _c("h3", [_vm._v("Let’s schedule a visit")])
                            ])
                          : _vm._e(),
                        _vm.showVisitSection
                          ? _c(
                              "div",
                              { staticClass: "input-box" },
                              [
                                _c("AtomRadio", {
                                  attrs: {
                                    id: "select-date",
                                    name: "visit",
                                    label: "Select a date",
                                    box: "date"
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "hide-select",
                                    attrs: { "data-tab": "select-date" }
                                  },
                                  [
                                    _c("AtomInput", {
                                      attrs: {
                                        type: _vm.inputTypes.DATE,
                                        value: _vm.date
                                      },
                                      on: {
                                        input: function($event) {
                                          _vm.date = $event
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("AtomRadio", {
                                  attrs: {
                                    id: "select-interval",
                                    name: "visit",
                                    label: "Select an interval",
                                    box: "interval"
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "hide-select",
                                    attrs: { "data-tab": "select-interval" }
                                  },
                                  [
                                    _c("AtomRadio", {
                                      attrs: {
                                        id: "this-week",
                                        name: "interval",
                                        label: "This Week",
                                        value: "This week"
                                      }
                                    }),
                                    _c("AtomRadio", {
                                      attrs: {
                                        id: "next-week",
                                        name: "interval",
                                        label: "Next Week",
                                        value: "Next week"
                                      }
                                    }),
                                    _c("AtomRadio", {
                                      attrs: {
                                        id: "this-month",
                                        name: "interval",
                                        label: "This Month",
                                        value: "This month"
                                      }
                                    }),
                                    _c("AtomRadio", {
                                      attrs: {
                                        id: "next-month",
                                        name: "interval",
                                        label: "Next Month",
                                        value: "Next month"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("AtomRadio", {
                                  attrs: {
                                    id: "i-dont-want-to-visit-the-space",
                                    name: "visit",
                                    label: "I don’t want to visit the space",
                                    box: "no-visit",
                                    value: "no-visit"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showVisitSection
                          ? _c("div", { staticClass: "title-subtitle" }, [
                              _c("h3", [_vm._v(" ")])
                            ])
                          : _vm._e(),
                        _c("AtomCheck", {
                          ref: "check",
                          staticClass: "row-checkbox",
                          attrs: {
                            id: "checkbox-contact",
                            label:
                              "I agree to the Terms & Conditions and Privacy Policy*",
                            error: _vm.errors.agree
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "buttons-bottom",
                            staticStyle: { "margin-top": "15px" }
                          },
                          [
                            _c("p", { staticClass: "required" }, [
                              _vm._v("All fields with * are required")
                            ]),
                            _c(
                              "a",
                              {
                                staticClass: "btn-red btn-cancel",
                                attrs: { href: "javascript:void(0)" },
                                on: { click: _vm.closeModal }
                              },
                              [_vm._v("Cancel")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn-green",
                                attrs: {
                                  type: "submit",
                                  value: "Request Offer"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                    Request Offer\n                  "
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-subtitle mt-0" }, [
      _c("h3", [_vm._v("I am interested in:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-subtitle" }, [
      _c("h3", [_vm._v("Company details:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-subtitle" }, [
      _c("h3", [_vm._v("Personal details:")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }