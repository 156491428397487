import {ActionContext} from "vuex";
import constants from "./constants";

const {
    LOAD_SPACE_DETAILS,
    TOGGLE_SPACE_VIEW,
    CHANGE_CURRENT_SPACE
} = constants.action;
const {
    SET_SPACE_DETAILS,
    SET_SPACE_VIEW_MODE,
    SET_CURRENT_SPACE,
} = constants.mutation;

export default {
    [LOAD_SPACE_DETAILS]({ commit, rootGetters }: ActionContext<any, any>, payload: any) {
        return new Promise((resolve, reject) => {
            rootGetters.requestHelper.GET(rootGetters.urlsHelper.landlord.space(payload)).then((res: any) => {
                commit(SET_SPACE_DETAILS, res.data.result);
                resolve({
                    result: res,
                    error: null
                });
            }).catch((e: any) => {
                commit(SET_SPACE_DETAILS, false);
                reject({
                    result: null,
                    error: e,
                });
            });
        });
    },
    [TOGGLE_SPACE_VIEW]({ commit }: ActionContext<any, any>, payload: any) {
        commit(SET_SPACE_VIEW_MODE,  payload);
    },
    [CHANGE_CURRENT_SPACE]({ commit, state }: ActionContext<any, any>, payload: any) {
        commit(SET_CURRENT_SPACE, payload);
    },
}