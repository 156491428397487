import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import {SpaceState} from "./types";

const state: SpaceState = {
    space: '',
    gallery: [],
    photos: [],
    videos: [],
    expanded: false,
    currentSpaceId: null,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}