import { RouteConfig } from "vue-router";

const ProjectRoutes = (context: any): Array<RouteConfig> => {
  const cfg = context.cfg;
  const portfolioPageDisabled = cfg.general.portfolioPageDisabled;
  const skipBuildingInit = cfg.general.skipBuildingInit;
  const landingName = !skipBuildingInit ? "Home" : "Space";
  const landingNameWhenPortfolio = !skipBuildingInit ? "Project" : "Space";
  const componentToRender = !skipBuildingInit ? "PageProject" : "PageSpace";

  const meta = {
    page: {
      meta: {
        ...cfg.project.meta,
        baseTitle: cfg.general.baseTitle,
      },
      sections: cfg.project.sections,
    },
  };

  return [
    {
      path: portfolioPageDisabled ? "/" : "/project",
      component: () =>
        import(
          /* webpackChunkName: "project-template" */ "../templates/ProjectTemplate.vue"
        ),
      children: [
        {
          path: "",
          name: portfolioPageDisabled ? landingName : landingNameWhenPortfolio,
          component: () =>
            import(
              /* webpackChunkName: "project/space-page" */ `../views/project/${componentToRender}.vue`
            ),
          meta,
        },
        {
          path: ":projectId",
          name: !skipBuildingInit ? "SpecificProject" : "SpecificProjectSpace",
          component: () =>
            import(
              /* webpackChunkName: "project/space-page" */ `../views/project/${componentToRender}.vue`
            ),
          meta,
        },
        {
          path: ":projectId/space/:spaceId",
          name: "SpecificSpace",
          component: () =>
            import(
              /* webpackChunkName: "project-space" */ "../views/project/PageSpace.vue"
            ),
          meta,
        },
        {
          path: ":projectId/floor/:floorId",
          name: "SpecificSpaceFloor",
          component: () =>
            import(
              /* webpackChunkName: "project-space" */ "../views/project/PageSpace.vue"
            ),
          meta,
        },
      ],
    },
    {
      path: portfolioPageDisabled ? "/embed" : "/embed/project",
      component: () =>
        import(
          /* webpackChunkName: "project-embed-template" */ "../templates/ProjectEmbedTemplate.vue"
        ),
      children: [
        {
          path: ":projectId",
          name: !skipBuildingInit
            ? "SpecificProjectEmbed"
            : "SpecificProjectSpaceEmbed",
          component: () =>
            import(
              /* webpackChunkName: "specific-project-embed" */ `../views/project/${componentToRender}Embed.vue`
            ),
          meta: {
            ...meta,
            page: {
              ...meta.page,
              isEmbedReady: true,
            },
          },
        },
        {
          path: ":projectId/space/:spaceId",
          name: "SpecificSpaceEmbed",
          component: () =>
            import(
              /* webpackChunkName: "specific-space-embed" */ `../views/project/PageSpaceEmbed.vue`
            ),
          meta: {
            ...meta,
            page: {
              ...meta.page,
              isEmbedReady: true,
            },
          },
        },
        // {
        //   path: ":projectId/floor/:floorId",
        //   name: "SpecificSpaceFloorEmbed",
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "specific-space-floor-embed" */ "../views/project/PageSpaceEmbed.vue"
        //     ),
        //   meta: {
        //     ...meta,
        //     page: {
        //       ...meta.page,
        //       isEmbedReady: true,
        //     },
        //   },
        // },
      ],
    },
  ];
};

export default ProjectRoutes;
