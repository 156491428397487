var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-atom-wrapper" }, [
    _vm.type === "checkbox" && _vm.type !== _vm.types.TEXTAREA
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.inputVal,
              expression: "inputVal"
            }
          ],
          ref: "input",
          attrs: {
            placeholder: _vm.placeholder,
            name: _vm.name,
            type: "checkbox"
          },
          domProps: {
            checked: Array.isArray(_vm.inputVal)
              ? _vm._i(_vm.inputVal, null) > -1
              : _vm.inputVal
          },
          on: {
            change: function($event) {
              var $$a = _vm.inputVal,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.inputVal = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.inputVal = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.inputVal = $$c
              }
            }
          }
        })
      : _vm.type === "radio" && _vm.type !== _vm.types.TEXTAREA
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.inputVal,
              expression: "inputVal"
            }
          ],
          ref: "input",
          attrs: {
            placeholder: _vm.placeholder,
            name: _vm.name,
            type: "radio"
          },
          domProps: { checked: _vm._q(_vm.inputVal, null) },
          on: {
            change: function($event) {
              _vm.inputVal = null
            }
          }
        })
      : _vm.type !== _vm.types.TEXTAREA
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.inputVal,
              expression: "inputVal"
            }
          ],
          ref: "input",
          attrs: {
            placeholder: _vm.placeholder,
            name: _vm.name,
            type: _vm.type
          },
          domProps: { value: _vm.inputVal },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.inputVal = $event.target.value
            }
          }
        })
      : _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.inputVal,
              expression: "inputVal"
            }
          ],
          ref: "textarea",
          attrs: { placeholder: _vm.placeholder },
          domProps: { value: _vm.inputVal },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.inputVal = $event.target.value
            }
          }
        }),
    _vm.error
      ? _c("span", { staticClass: "error-message" }, [
          _vm._v(_vm._s(_vm.error))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }