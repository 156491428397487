var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { "molecule-request-offer": true, active: _vm.isShown },
      attrs: { id: "send-request" }
    },
    [
      _c("div", { staticClass: "popup-screen small-popup-screen" }, [
        _c(
          "span",
          { staticClass: "btn-close-popup", on: { click: _vm.closeModal } },
          [_c("AtomIcon", { attrs: { icon: "xmark", size: 16 } })],
          1
        ),
        _c("div", { staticClass: "content-center" }, [
          _c("div", { staticClass: "content-center-wrapper" }, [
            _vm._m(0),
            _c(
              "a",
              {
                staticClass: "btn-green",
                attrs: { href: "javascript:void(0)" },
                on: { click: _vm.closeModal }
              },
              [_vm._v("Continue")]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-subtitle" }, [
      _c("h3", [_vm._v("Your request has been submitted")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }