import { ActionContext } from "vuex";
import constants from "./constants";

const {
  LOAD_LANDLORD_DETAILS,
  LOAD_PROJECT_AVAILABILITY,
  CHANGE_LOADING_STATE,
  CHANGE_ACTIVE_PROJECT,
  CHANGE_FILTER_STATE,
  TOGGLE_INFO_BOX,
  DOWNLOAD_FILE,
  RESET_FILTERS,
  RESET_FLOOR_FILTER,
  LOAD_TOTAL_AVAILABLE_RESULTS,
} = constants.action;
const {
  SET_LANDLORD_DETAILS,
  SET_PROJECT_AVAILABILITY,
  SET_LOADING_STATE,
  SET_PROJECT_LOADING_STATE,
  SET_INFO_BOX_STATE,
  SET_ACTIVE_PROJECT,
  SET_FILTER_STATE,
  SET_EMPTY_FILTERS,
  SET_TOTAL_AVAILABLE_RESULTS,
} = constants.mutation;

export default {
  [LOAD_LANDLORD_DETAILS]({
    commit,
    dispatch,
    rootGetters,
    rootState,
  }: ActionContext<any, any>) {
    const base = rootGetters.constants.base;
    const baseState = rootState.base;
    const changePageLoading = (value: boolean) => {
      if (baseState.documentIsReady) {
        dispatch(
          base.withNamespace(base.action.CHANGE_PAGE_LOADING_STATE),
          value,
          { root: true }
        );
      }
    };
    commit(SET_LOADING_STATE, true);
    changePageLoading(true);
    return new Promise((resolve, reject) => {
      rootGetters.requestHelper
        .GET(rootGetters.urlsHelper.landlord.details())
        .then((res: any) => {
          commit(SET_LANDLORD_DETAILS, res.data.result);
          commit(SET_LOADING_STATE, false);
          resolve({
            result: res,
            error: null,
          });
          if (res.data.result.projects.length) {
            const defaultProject = { ...res.data.result.projects[0] };
            const currentRoute = rootGetters.router.app.$route;
            if (
              ([
                "SpecificProject",
                "SpecificSpace",
                "Space",
                "SpecificProjectSpace",
              ].includes(currentRoute.name) ||
                currentRoute.meta.isProjectStaticPage) &&
              currentRoute.params.projectId
            ) {
              const foundProject = res.data.result.projects.find(
                (item: any) => item.slug === currentRoute.params.projectId
              );
              if (foundProject) {
                commit(SET_ACTIVE_PROJECT, foundProject.slug);
                dispatch(LOAD_PROJECT_AVAILABILITY, foundProject.id);
              } else {
                commit(SET_ACTIVE_PROJECT, defaultProject.slug);
                dispatch(LOAD_PROJECT_AVAILABILITY, defaultProject.id);
                rootGetters.router.push({ name: "NotFound", params: {} });
              }
            } else {
              commit(SET_ACTIVE_PROJECT, defaultProject.slug);
              dispatch(LOAD_PROJECT_AVAILABILITY, defaultProject.id);
            }
          } else {
            changePageLoading(false);
          }
        })
        .catch((e: any) => {
          commit(SET_LOADING_STATE, false);
          changePageLoading(false);
          reject({
            result: null,
            error: e,
          });
        });
    });
  },
  async [LOAD_PROJECT_AVAILABILITY](
    {
      commit,
      dispatch,
      state,
      rootGetters,
      rootState,
    }: ActionContext<any, any>,
    payload: any
  ) {
    commit(SET_PROJECT_LOADING_STATE, true);
    const base = rootGetters.constants.base;
    const baseState = rootState.base;
    const changePageLoading = (value: boolean) => {
      if (baseState.documentIsReady) {
        dispatch(
          base.withNamespace(base.action.CHANGE_PAGE_LOADING_STATE),
          value,
          { root: true }
        );
      }
    };
    return new Promise((resolve, reject) => {
      rootGetters.requestHelper
        .GET(rootGetters.urlsHelper.landlord.availability(payload))
        .then((res: any) => {
          commit(SET_PROJECT_AVAILABILITY, res.data.result);
          commit(SET_PROJECT_LOADING_STATE, false);
          changePageLoading(false);
          resolve({
            result: res,
            error: null,
          });
        })
        .catch((e: any) => {
          commit(SET_PROJECT_LOADING_STATE, false);
          changePageLoading(false);
          reject({
            result: null,
            error: e,
          });
        });
    });
  },
  [CHANGE_LOADING_STATE](
    { commit, state }: ActionContext<any, any>,
    payload: boolean
  ) {
    commit(SET_LOADING_STATE, payload);
  },
  [CHANGE_ACTIVE_PROJECT](
    { commit, dispatch, state, rootGetters }: ActionContext<any, any>,
    payload: string
  ) {
    commit(SET_ACTIVE_PROJECT, payload);
    commit(SET_PROJECT_LOADING_STATE, true);
    const projectData = state.projects.find(
      (project: any) => project.slug === payload
    );
    return new Promise((resolve, reject) => {
      rootGetters.requestHelper
        .GET(rootGetters.urlsHelper.landlord.availability(projectData.id))
        .then((res: any) => {
          commit(SET_PROJECT_AVAILABILITY, res.data.result);
          commit(SET_PROJECT_LOADING_STATE, false);
          const requestOffer = rootGetters.constants.requestOffer;
          dispatch(
            requestOffer.withNamespace(requestOffer.action.CLEAR_STATE),
            null,
            { root: true }
          );
          resolve({
            result: res,
            error: null,
          });
        })
        .catch((e: any) => {
          commit(SET_PROJECT_LOADING_STATE, false);
          reject({
            result: null,
            error: e,
          });
        });
    });
  },
  [CHANGE_FILTER_STATE](
    { commit, state }: ActionContext<any, any>,
    payload: any
  ) {
    commit(SET_FILTER_STATE, payload);
  },
  [TOGGLE_INFO_BOX]({ commit }: ActionContext<any, any>, payload: any) {
    commit(SET_INFO_BOX_STATE, {
      open: payload.open,
    });
  },
  [DOWNLOAD_FILE](
    { commit, state, rootState, rootGetters }: ActionContext<any, any>,
    payload: any
  ) {
    return new Promise((resolve, reject) => {
      rootGetters.requestHelper
        .POST(rootGetters.urlsHelper.landlord.downloadFile(), payload)
        .then((res: any) => {
          resolve({
            result: res.data.result,
            error: null,
          });
        })
        .catch((e: any) => {
          reject({
            result: null,
            error: e,
          });
        });
    });
  },
  [RESET_FILTERS]({ commit }: ActionContext<any, any>) {
    commit(SET_EMPTY_FILTERS, {
      building: -1,
      floor: -1,
      spaceType: -1,
      workspaces: -1,
      amenities: [],
    });
  },
  [RESET_FLOOR_FILTER]({ commit }: ActionContext<any, any>) {
    commit(SET_EMPTY_FILTERS, {
      floor: -1,
    });
  },
  [LOAD_TOTAL_AVAILABLE_RESULTS](
    { commit }: ActionContext<any, any>,
    payload: any
  ) {
    commit(SET_TOTAL_AVAILABLE_RESULTS, payload);
  },
};
