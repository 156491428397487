<template>
  <div class="atom-radio-box" :data-box="box">
    <input :id="id" type="radio" :name="name" @change="onRadioClick" :value="value"/>
    <label :for="id">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: "AtomRadio",
  components: {},
  props: {
    id: {
      required: false,
      type: String,
      default: '',
    },
    label: {
      required: false,
      type: String,
      default: '',
    },
    name: {
      required: false,
      type: String,
      default: 'checkbox',
    },
    box: {
      required: false,
      type: String,
    },
    value:{
      required: false,
      type: String,
    },
  },
  methods: {
    onRadioClick() {
      const radioButton = this.$el;
      this.$el.parentNode.querySelectorAll('.hide-select').forEach((input) => {
        if (radioButton.getAttribute('data-box') === 'no-visit') {
          input.classList.remove('active');
        }
        if (input.getAttribute('data-tab') === `select-${ radioButton.getAttribute('data-box') }` && !input.classList.contains('active')) {
          input.classList.add('active');
        } else {
          input.classList.remove('active');
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.atom-radio-box {
  position: relative;
  padding-left: 1.875rem;
  display: inline-block;
  cursor: pointer;

  @media only screen and (max-width: 767px) {
    padding-left: 30px;
  }

  input[type='radio'] {
    display: none;
  }

  label {
    font-weight: $regular;
    font-size: 0.875rem;
    line-height: 1.063rem;
    color: #0A0A0A;
    cursor: pointer;
    @media only screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }

    &:after {
      content: "";
      display: block;
      width: 0.875rem;
      height: 0.875rem;
      border-radius: 0.875rem;
      border: 0.125rem solid $black;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      @media only screen and (max-width: 767px) {
        width: 14px;
        height: 14px;
        border-radius: 14px;
        border: 2px solid $black;
      }
    }

    &:before {
      opacity: 0;
      content: "";
      display: block;
      width: 0.625rem;
      height: 0.625rem;
      border-radius: 0.625rem;
      background: $second-color;
      position: absolute;
      left: 0.25rem;
      top: 50%;
      transform: translateY(-50%);
      @media only screen and (max-width: 767px) {
        left: 4px;
        width: 10px;
        height: 10px;
        border-radius: 10px;
      }
    }
  }

  input[type='radio'] {
    &:checked + label {
      &:before {
        opacity: 1;
      }
    }
  }
}
</style>