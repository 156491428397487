<template>
  <div id="send-request" :class="{'molecule-request-offer': true, 'active': isShown}">
    <div class="popup-screen small-popup-screen">
      <span class="btn-close-popup" @click="closeModal"><AtomIcon icon="xmark" :size="16" /></span>
      <div class="content-center">
        <div class="content-center-wrapper">
          <div class="title-subtitle">
            <h3>Your request has been submitted</h3>
          </div>
          <a class="btn-green" href="javascript:void(0)" @click="closeModal">Continue</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomIcon from "../../atoms/common/AtomIcon";
export default {
  name: "MoleculeSendRequest",
  components: {AtomIcon},
  data() {
    return {
      isShown: false,
    }
  },
  methods: {
    toggleShow() {
      this.isShown = !this.isShown;
    },
    closeModal() {
      const modal = this.$store.getters.constants.modal;
      this.$store.dispatch(modal.withNamespace(modal.action.CLOSE_MODAL));
      document.body.classList.remove('disable-scroll');
    },
  }
}
</script>

<style lang="scss" scoped>
.molecule-request-offer {
  display: none;
  &.active {
    display: block;
  }
}
</style>