// Actions
const LOAD_SPACE_DETAILS = "loadSpaceDetails";
const TOGGLE_SPACE_VIEW = "toggleSpaceView";
const CHANGE_CURRENT_SPACE = "changeCurrentSpace";

//mutations
const SET_SPACE_DETAILS = "setSpaceDetails";
const SET_SPACE_VIEW_MODE = "setSpaceViewMode";
const SET_CURRENT_SPACE = "setCurrentSpace";

// Getters
const GET_SPACE = "getSpace";

// Namespace
const NAMESPACE = "space"

export const withNamespace = (string: string) => {
    return `${ NAMESPACE }/${ string }`;
}

export default {
    action: {
        LOAD_SPACE_DETAILS,
        TOGGLE_SPACE_VIEW,
        CHANGE_CURRENT_SPACE,
    },
    mutation: {
        SET_SPACE_DETAILS,
        SET_SPACE_VIEW_MODE,
        SET_CURRENT_SPACE,
    },
    getter: {
        GET_SPACE
    },
    namespace: NAMESPACE,
    withNamespace
}